import React, { useState } from "react"
import { Box, CircularProgress, TextField } from "@mui/material"
import { useFormik } from "formik"
import { ButtonComponent } from "../../components/ButtonComponent"
import { RoundedTextField } from "../../components/RoundedTextField"
import { SoldOutProductForm } from "../../types/server/class/SoldOutProductWatching"
import { api } from "../../api"
import { useFranchise } from "../../hooks/useFranchise"

interface SoldOutFormProps {
    product: Product
}

export const SoldOutForm: React.FC<SoldOutFormProps> = ({ product }) => {
    const { franchise } = useFranchise()

    const [loading, setLoading] = useState(false)
    const [confirmed, setConfirmed] = useState(false)

    const formik = useFormik<SoldOutProductForm>({
        initialValues: { product_id: product.id, email: "", name: "", phone: "", franchise_id: franchise?.id || 2 },
        async onSubmit(values, formikHelpers) {
            if (loading && confirmed) return
            setLoading(true)

            try {
                const response = await api.post("/products/soldout-form", values)
                setConfirmed(true)
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false)
            }
        },
    })

    return (
        <Box sx={{ flexDirection: "column", gap: "1vw" }}>
            <Box sx={{ fontWeight: "bold", alignSelf: "center" }}>Me avise quando estiver disponível</Box>
            <RoundedTextField label="Nome" value={formik.values.name} onChange={formik.handleChange} required name="name" />
            <RoundedTextField label="E-mail" value={formik.values.email} onChange={formik.handleChange} required name="email" />
            <RoundedTextField label="Whatsapp" value={formik.values.phone} onChange={formik.handleChange} required name="phone" />
            <ButtonComponent onClick={() => formik.handleSubmit()}>
                {loading ? <CircularProgress size="1.75rem" color="secondary" /> : confirmed ? "Enviado!" : "Me avise"}
            </ButtonComponent>
        </Box>
    )
}
