import React, { useEffect, useState } from "react"
import { AlertColor, Box, Skeleton, SxProps, useMediaQuery } from "@mui/material"
import { Order as BozpayOrder } from "boz.pay.component"
import { CurrencyText } from "./CurrencyText"
import { Product } from "./Checkout/Product"
import { PendingPayment } from "./PendingPayment"
import statusEnum from "../tools/enumStatus"
import { useArray } from "burgos-array"
import { useColors } from "../hooks/useColors"
import { useNavigate } from "react-router-dom"
import { FaUserAlt } from "react-icons/fa"
import { FaPhone } from "react-icons/fa6"
import { TiLocation } from "react-icons/ti"
import { ButtonComponent } from "./ButtonComponent"
import { OrderModal } from "./OrderModal"
import { api, bozpayApi } from "../api"
import { useFranchise } from "../hooks/useFranchise"
import { Order } from "../types/server/class/Order"
import { Coupon } from "../types/server/class/Coupon"
interface OrderComponentProps {
    initialOrder: BozpayOrder
    viewOrder?: boolean
}

interface DataTextProps {
    title: string
    value: React.ReactNode
    color?: AlertColor
    bold?: boolean
}

const DataText: React.FC<DataTextProps> = ({ title, value, color, bold }) => {
    const isMobile = useMediaQuery("(orientation: portrait)")
    return (
        <Box
            sx={{
                fontSize: isMobile ? "4vw" : "1.5rem",
                alignItems: "center",
                padding: isMobile ? "1vw" : 0,
                color: "primary.main",
                fontFamily: "poppins",
                fontWeight: bold ? "bold" : "",
                gap: "1vw",
            }}
        >
            {title} <Box sx={{ fontSize: "1rem", color: `${color}.main`, fontWeight: bold ? "bold" : "" }}>{value}</Box>
        </Box>
    )
}

const RealText: React.FC<{ value: string | number }> = ({ value }) => <CurrencyText value={value} style={{ fontSize: "1rem" }} />

export const OrderComponent: React.FC<OrderComponentProps> = ({ initialOrder, viewOrder }) => {
    const [bozpayOrder, setBozpayOrder] = useState(initialOrder)
    const [order, setOrder] = useState<Order | null>(null)
    const [coupon, setCoupon] = useState<Coupon | null>(null)
    const products = order?.products

    const isMobile = useMediaQuery("(orientation: portrait)")
    const subtotal = bozpayOrder.products.reduce((totalPrice, product) => totalPrice + product.price * product.quantity, 0)
    const status = statusEnum(bozpayOrder.status)
    const navigate = useNavigate()

    const skeletons = useArray().newArray(2)
    const colors = useColors()
    const { bozpayStoreIdentifier } = useFranchise()

    const [openReviewModal, setOpenReviewModal] = useState(false)

    const maybe_delivered = bozpayOrder.status === "Em trânsito" || bozpayOrder.status === "Concluído"
    const all_reviewed = products?.every((item) => item.rating !== null)

    console.log({ all_reviewed: products })

    const updateOrder = async () => {
        try {
            const response = await bozpayApi.post("/order", { store: bozpayStoreIdentifier, referenceId: bozpayOrder.referenceId })
            setBozpayOrder(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const fetchOrder = async () => {
        if (!bozpayOrder) return

        try {
            const response = await api.get("/order", { params: { order_id: bozpayOrder.referenceId } })
            setOrder(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    const fetchCoupon = async () => {
        if (!order) return
        try {
            const response = await api.get("/coupon", { params: { coupon_id: order.coupon_id } })
            console.log({ coupon: response.data })
            setCoupon(response.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        fetchOrder()
    }, [bozpayOrder])

    useEffect(() => {
        if (order?.coupon_id) fetchCoupon()
    }, [order])

    return (
        <Box
            sx={{
                flexDirection: "column",
                gap: isMobile ? "2vw" : "1vw",
                border: "1px solid  gray",
                padding: isMobile ? "4vw" : "1vw",
                borderRadius: isMobile ? "4vw" : "1vw",
            }}
        >
            <Box sx={{ justifyContent: "space-between", alignItems: "center", flexDirection: "row" }}>
                {viewOrder && (
                    <h4 style={{ fontFamily: "BowlbyOneSC", fontWeight: "600", color: colors.primary }}>Pedido #{bozpayOrder.referenceId}</h4>
                )}
                {viewOrder && (
                    <p
                        style={{ textDecoration: "underline", fontSize: isMobile ? "3.5vw" : "1rem", fontFamily: "BowlbyOneSC", cursor: "pointer" }}
                        onClick={() => navigate(`/order/${bozpayOrder.referenceId}`)}
                    >
                        Ver Pedido{" >"}
                    </p>
                )}
            </Box>
            {bozpayOrder.status == "PENDING" && <PendingPayment orderId={bozpayOrder.referenceId} />}

            <Box alignItems="center" justifyContent={"space-between"}>
                <DataText title="Status: " value={status.text} color={status.color} bold />
                <p style={{ fontSize: "0.9rem", color: colors.primary }}>{new Date(Number(bozpayOrder.dateCreated)).toLocaleDateString("pt-br")} </p>
            </Box>
            {maybe_delivered && !all_reviewed && (
                <ButtonComponent onClick={() => setOpenReviewModal(true)} style={{ margin: isMobile ? "5vw 0" : "1vw 0" }}>
                    {bozpayOrder.status === "Em trânsito" ? "Confirmar recebimento" : "Avaliar pedido"}
                </ButtonComponent>
            )}
            {!viewOrder && (
                <>
                    <p style={{ fontFamily: "BowlbyOneSC", color: "gray", fontSize: "1.0rem" }}>Endereço de entrega</p>
                    <Box
                        sx={{
                            width: 1,
                            padding: isMobile ? "3vw" : "1vw",
                            flexDirection: "column",
                            borderRadius: isMobile ? "5vw" : "1vw",
                            border: "1px solid gray",
                            height: "fit-content",
                            overflowY: "auto",
                            maxHeight: "50vw",
                            gap: isMobile ? "3vw" : "1vw",
                            alignItems: "center",
                        }}
                    >
                        <Box sx={{ gap: "2vw", alignItems: "center" }}>
                            <TiLocation color="gray" style={{ width: isMobile ? "10vw" : "2vw", height: isMobile ? "10vw" : "2vw" }} />
                            <p style={{ fontWeight: "bold", color: "rgba(85, 85, 85, 1)", fontSize: "0.85rem" }}>
                                {bozpayOrder.billing.address.address},{bozpayOrder.billing.address.number} - {bozpayOrder.billing.address.district},{" "}
                                {bozpayOrder.billing.address.city} - {bozpayOrder.billing.address.state}, {bozpayOrder.billing.address.postcode}
                            </p>
                        </Box>
                        {/* <Box sx={{ gap: "2vw" }}>
                            <FaUserAlt color="gray" style={ { width: "4vw", height: "4vw" } } />
                          
                        </Box>
                        <Box sx={{ gap: "2vw" }}>
                            <FaPhone color="gray" style={{ width: "4vw", height: "4vw" }} />
                        </Box> */}
                    </Box>
                </>
            )}

            <Box sx={{ flexDirection: "column", gap: isMobile ? "4vw" : "1vw", p: isMobile ? "1vw" : 0 }}>
                <p style={{ fontFamily: "BowlbyOneSC", color: "gray", fontSize: "1rem" }}>Itens ({products?.length})</p>
                <Box
                    sx={{
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: isMobile ? "5vw" : "2vw",
                        padding: isMobile ? "3vw" : "1vw",
                        borderRadius: isMobile ? "5vw" : "1vw",
                        border: "1px solid gray",
                        height: "fit-content",
                    }}
                >
                    {!!products?.length
                        ? products.map((item) => (
                              // é o mesmo componente usado no checkout, cuidado ao editar

                              <Product
                                  key={item.id}
                                  product={{
                                      ...item,
                                      cover: "",
                                      brand: "",
                                      sold: 0,
                                      discountPercentage: 0,
                                      originalPrice: 0,
                                      promotionalPrice: 0,
                                      rating: 0,
                                      id: Number(item.referenceId),
                                      code: "",
                                      ratingQtd: 0,
                                      dropshipping: 0,
                                  }}
                                  hideCloseButton
                                  review={{
                                      orderId: Number(bozpayOrder.referenceId),
                                      rating: item.rating || 0,
                                      referenceId: Number(item.referenceId),
                                      comment: item.review || undefined,
                                  }}
                              />
                          ))
                        : skeletons.map((index) => (
                              <Box>
                                  <Skeleton variant="rounded" animation="wave" key={index} sx={{ width: "100%", height: "100vw" }} />
                              </Box>
                          ))}
                </Box>
                <hr></hr>
                <Box
                    sx={{
                        flexDirection: "column",
                        alignItems: "end",
                        gap: isMobile ? "2vw" : "1vw",
                    }}
                >
                    <p style={{ fontSize: "1rem", color: "gray" }}>
                        <span style={{ fontWeight: "600" }}>Subtotal de Itens: </span>
                        <CurrencyText value={subtotal} />
                    </p>
                    <p style={{ fontSize: "1rem", color: "gray" }}>
                        <span style={{ fontWeight: "600" }}> Frete: </span>
                        {order && <CurrencyText value={order.shippingPrice} />}
                    </p>
                    {coupon && (
                        <p style={{ fontSize: "1rem", color: "gray" }}>
                            <span style={{ fontWeight: "600" }}> Cupom ({coupon.code}): </span>
                            <CurrencyText value={(coupon.is_percent ? (coupon.value / 100) * subtotal : coupon.value) * -1} />
                        </p>
                    )}
                    <p style={{ fontSize: "1rem", color: "gray" }}>
                        <span style={{ fontWeight: "600" }}> Total:</span> <CurrencyText value={bozpayOrder.total} />
                    </p>
                </Box>
            </Box>
            <OrderModal order={bozpayOrder} externallyOpen={openReviewModal} setExternallyOpen={setOpenReviewModal} updateOrder={updateOrder} />
        </Box>
    )
}
